import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AuthApi } from 'api/AuthApi';
import { RefreshTokenApi } from 'api/RefreshTokenApi';
import { TrackingApi } from 'api/TrackingApi';
import { VisitsApi } from 'api/VisitsApi';
import { getAccessTokenDataFromStorage } from 'helpers/auth';
import { TRACK_START_APP_SESSION_STORAGE_KEY } from 'helpers/constants';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';
import { getUserIdFromCookies } from 'helpers/userId';
import { useSystemSearchQueries } from 'hooks/useSystemSearchQueries';
import { setIsAuthenticated } from 'store/auth/authSlice';

export const useSessionStartTrack = () => {
  const dispatch = useDispatch();

  const { autoLoginQuery } = useSystemSearchQueries();

  const trackStartSession = useCallback(
    async (isSaveEvent?: boolean) => {
      const isAlreadyTracked = getSessionStorageItem(
        TRACK_START_APP_SESSION_STORAGE_KEY
      );

      let translationsBuildVersion = '';
      let isTrackWithLogin = false;

      if (isAlreadyTracked) {
        try {
          const { data } = await VisitsApi.getTranslationBuildId();

          if (data.trans_build_id) {
            return data.trans_build_id;
          }
        } catch (err) {
          console.error(err);
        }

        return translationsBuildVersion;
      }

      if (autoLoginQuery.value) {
        try {
          const { access_token, expires_in } = await AuthApi.autoLogin({
            loginToken: autoLoginQuery.value,
          });

          isTrackWithLogin = true;

          dispatch(
            setIsAuthenticated({
              expire: expires_in,
              accessToken: access_token,
              isAuthenticated: true,
            })
          );
        } catch (err: any) {
          console.error(err);
        }
      }

      if (getUserIdFromCookies() && !getAccessTokenDataFromStorage().token) {
        // ? hack for users with old authorization
        try {
          const { access_token, expires_in } =
            await RefreshTokenApi.refreshAccessToken({ token: null });

          dispatch(
            setIsAuthenticated({
              accessToken: access_token,
              expire: expires_in,
              isAuthenticated: true,
            })
          );
        } catch (err: any) {
          console.error(err);
        }
      }

      try {
        const { trans_build_id: buildVersion } =
          await TrackingApi.track(isTrackWithLogin);

        translationsBuildVersion = String(buildVersion);

        window.from_base_event = true;
      } catch (err) {
        console.error(err);
      }

      if (isSaveEvent) {
        setSessionStorageItem(TRACK_START_APP_SESSION_STORAGE_KEY, '1');
      }

      return translationsBuildVersion;
    },
    [autoLoginQuery.value, dispatch]
  );

  return {
    trackStartSession,
  };
};
