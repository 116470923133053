import TiktokPixel from 'tiktok-pixel';

import {
  ConversionEventName,
  ConversionEventType,
} from 'types/enums/ConversionEvent';
import { ConversionEventData } from 'types/interfaces/ConversionEventData';

import { ConversionsApi } from 'api/ConversionsApi';
import { getLocalStorageItem } from 'helpers/localStorage';

export const TikTokPixel = {
  init({ newPixelId }: { newPixelId: string | number }) {
    TiktokPixel.init(String(newPixelId));
    TiktokPixel.pageView();
  },

  mapEventTypeToEventName(eventType: ConversionEventType) {
    switch (eventType) {
      case ConversionEventType.PhotoUpload: {
        return ConversionEventName.AddToCart;
      }
      case ConversionEventType.MailConfirm: {
        return ConversionEventName.AddToWishlist;
      }
      case ConversionEventType.Registration: {
        return ConversionEventName.CompleteRegistration;
      }
      case ConversionEventType.FirstPurchase: {
        return ConversionEventName.Purchase;
      }
      case ConversionEventType.PurchaseTotal: {
        return ConversionEventName.PurchaseTotal;
      }
      case ConversionEventType.UserSpent1cr: {
        return ConversionEventName.UserSpent1cr;
      }
      case ConversionEventType.UserSpent5cr: {
        return ConversionEventName.UserSpent5cr;
      }
      case ConversionEventType.UserSpent10cr: {
        return ConversionEventName.UserSpent10cr;
      }
      case ConversionEventType.UserSpent15cr: {
        return ConversionEventName.UserSpent15cr;
      }
      case ConversionEventType.UserSpent20cr: {
        return ConversionEventName.UserSpent20cr;
      }
      case ConversionEventType.FindLocation: {
        return ConversionEventName.FindLocation;
      }
      case ConversionEventType.EngagedUserV1: {
        return ConversionEventName.EngagedUserV1;
      }
      case ConversionEventType.EU30min: {
        return ConversionEventName.EU30min;
      }
      case ConversionEventType.EUSubmit: {
        return ConversionEventName.EUSubmit;
      }
      case ConversionEventType.EURetention: {
        return ConversionEventName.EURetention;
      }
      default:
        return null;
    }
  },

  trackEvent(payload: { eventData: ConversionEventData; isRetry: boolean }) {
    const eventID = payload.eventData?.event_id;
    const eventType = payload.eventData?.event_type;
    const eventName = this.mapEventTypeToEventName(eventType);

    const isRetry = !!payload.isRetry;
    const user = payload.eventData?.custom_data?.user;
    const properties = payload.eventData?.custom_data?.properties;

    if (
      !eventType ||
      !eventName ||
      !Object.values(ConversionEventName).includes(eventName)
    )
      return;

    const pixelCustomParams = { ...user, ...properties, eventID };

    TiktokPixel.track(eventName, pixelCustomParams);

    if (eventType) ConversionsApi.forceServerConversion({ eventType, isRetry });

    if (getLocalStorageItem('conversions_debug')) {
      // eslint-disable-next-line no-console
      console.log('[TIKTOK]', eventName);
    }
  },
};
