import React, { useCallback, useEffect } from 'react';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import { usePopupTracking } from 'hooks/tracking/usePopupTracking';

import { ReactComponent as DisappearIcon } from 'assets/icons/disapper.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';

import css from './disappearingMessagePopup.module.sass';

interface Props {
  onClose: () => void;
}

export const DisappearingMessagePopup: React.FC<Props> = ({ onClose }) => {
  const { trackPopup } = usePopupTracking();

  const handleClose = useCallback(() => {
    trackPopup({
      popupName: PopupName.DisappearingMessage,
      operation: PopupOperation.ClickClose,
    });

    onClose();
  }, [onClose, trackPopup]);

  useEffect(() => {
    trackPopup({
      popupName: PopupName.DisappearingMessage,
      operation: PopupOperation.Show,
    });

    return () => {
      trackPopup({
        popupName: PopupName.DisappearingMessage,
        operation: PopupOperation.Close,
      });
    };
  }, [trackPopup]);

  return (
    <Popup>
      <div className={css.root}>
        <DisappearIcon className={css.icon} />

        <h2 className={css.title}>
          We are sorry, this message has been removed. Don&apos;t miss your
          chance next time.
        </h2>

        <BaseButton
          className={css.btn}
          type={ButtonTypes.Accent}
          onClick={handleClose}
        >
          Ok
        </BaseButton>
      </div>
    </Popup>
  );
};
