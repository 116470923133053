import { createSelector } from '@reduxjs/toolkit';

import { ProfileType } from 'types/enums/ProfileType';

import { RootState } from 'store/rootReducer';

export const getIsAuthorizationLoading = (state: RootState) =>
  state.auth.loading;
export const getIsAuthenticated = (state: RootState) =>
  !!state.auth.isAuthenticated;
export const getUser = (state: RootState) => state.auth.user;
export const getUserId = (state: RootState) => state.auth.user?.ulid_id;
export const getIsRegularUser = (state: RootState) =>
  state.auth.user?.type === ProfileType.User;

export const getUserEmail = createSelector(getUser, (user) => user?.email);
export const getUserEmailSubscriptionStatus = createSelector(
  getUser,
  (user) => user?.subscription_email_status
);

export const getIsCommonStateLoaded = createSelector(getUser, (user) => !!user);
