import { httpClient } from './httpClient';

type AccountSettings = {
  email: {
    name: string;
    value: string;
  };
  id: {
    name: string;
    value: number;
  };
  notifications: {
    name: string;
    value: boolean;
  };
  password: {
    name: string;
    value: string;
  };
};

export interface ContactUsFormPayload {
  email?: string;
  message: string;
  subject: 'contact us' | 'delete account';
}

export const SettingsApi = {
  async fetchSettings() {
    const { data } = await httpClient.get<AccountSettings>('/user/settings');

    return data;
  },

  async sendEmailConfirmation() {
    const { data } = await httpClient.patch(
      '/user/settings/email-verification'
    );

    return data;
  },

  async changeEmail(payload: { email: string }) {
    return httpClient.patch('/user/settings/email', payload);
  },

  async changePassword(payload: { password: string }) {
    return httpClient.patch('/user/settings/password', payload);
  },

  async changeSubscription(payload: {
    url: string;
    origin: string;
    receive_email: boolean;
    delivery_id?: number;
    partner_login_id?: number;
    event_data_id?: number;
    reason: string[];
    feedback: string;
  }) {
    return httpClient.patch('/user/settings/subscription', {
      ...payload,
      app_load_id: window?.appLoadId,
      source: payload.delivery_id ? 'mails' : 'settings',
      pageLoadId: window?.pageLoadId,
    });
  },

  async sendEmailToSupport(payload: ContactUsFormPayload) {
    const { data } = await httpClient.post('/contact-us', payload);

    return data;
  },

  async unsubscribeFromMailing() {
    const { data } = await httpClient.get('/user/do-not-sell');

    return data;
  },

  async deleteProfile() {
    return httpClient.delete('/user');
  },

  async fetchTerms(termsSource: string) {
    const { data } = await httpClient.get<{ terms: string; policy: string }>(
      termsSource
    );

    return { terms: data.terms || data.policy || '' };
  },

  async fetchDescriptor() {
    const { data } = await httpClient.get<{
      address: string;
      descriptor: string;
    }>('/descriptor');

    return data;
  },

  async updateNotificationsSoundFlag(payload: {
    url: string;
    origin: string;
    enabled: boolean;
  }) {
    return httpClient.patch(`/user/settings/sound-notification`, {
      pageLoadId: window?.pageLoadId,
      ...payload,
    });
  },
};
