import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import {
  QueryKeys,
  useSystemSearchQueries,
} from 'hooks/useSystemSearchQueries';

export const useClickIdAssign = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const {
    clickIdQuery,
    fbclidQuery,
    ttclidQuery,
    twclidQuery,
    gclidQuery,
    wbraidQuery,
    gbraidQuery,
  } = useSystemSearchQueries();

  useEffect(() => {
    const matchingParam =
      fbclidQuery.value ||
      gclidQuery.value ||
      wbraidQuery.value ||
      gbraidQuery.value ||
      ttclidQuery.value ||
      twclidQuery.value;

    if (matchingParam && !clickIdQuery.value) {
      const queryStringWithoutCLickId = queryString.exclude(search, [
        QueryKeys.ClickId,
      ]);

      navigate(
        {
          search: `${queryStringWithoutCLickId}&${QueryKeys.ClickId}=${matchingParam}`,
        },
        { replace: true }
      );
    }
  }, [
    clickIdQuery.value,
    fbclidQuery.value,
    gbraidQuery.value,
    gclidQuery.value,
    navigate,
    search,
    ttclidQuery.value,
    twclidQuery.value,
    wbraidQuery.value,
  ]);
};
