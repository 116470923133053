import { ConversionEventType } from 'types/enums/ConversionEvent';
import { ConversionEventData } from 'types/interfaces/ConversionEventData';

import { httpClient } from './httpClient';

export interface ConversionQueue {
  partner_type: 'GTM' | 'FACEBOOK' | 'TIK_TOK';
  event_type: ConversionEventType;
  execute_at: string;
}

export const ConversionsApi = {
  async createConversionData(eventType: ConversionEventType) {
    const { data } = await httpClient.post<ConversionEventData>(
      'conversion/create-data',
      {
        event_type: eventType,
      }
    );

    return data;
  },

  forceServerConversion(payload: {
    eventType: ConversionEventType;
    isRetry: boolean;
  }) {
    return httpClient.post('conversion/send', {
      event_type: payload.eventType,
      ...(payload.isRetry && { retry: 1 }),
    });
  },

  async getConversionsQueue() {
    const { data } = await httpClient.get<{ data: ConversionQueue[] }>(
      'conversion/get-queue'
    );

    return data;
  },
};
