import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { LAST_ONLINE_TS_STORAGE_KEY } from 'config/storageConstants';
import { MirrorService } from 'services/MirrorService';

import { AuthApi, OnlineType } from 'api/AuthApi';
import { TrackingApi } from 'api/TrackingApi';
import { getAccessTokenDataFromStorage } from 'helpers/auth';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';
import { getIsCommonStateLoaded } from 'store/auth/selectors';

import { WebSocketContext } from 'components/shared/WebSocketProvider';

import { useInactiveBrowserTab } from './useInactiveBrowserTab';

const PING_INTERVAL = 60 * 1000;
const SITE_VERSION_KEY = 'x_version';

export const useOnlinePingV2 = (isAuthenticated: boolean) => {
  const lastPing = useRef<number>(0);
  const isAppLoad = useRef<boolean>(true);

  const { wsCheckIds } = useContext(WebSocketContext);

  const isCommonStateLoaded = useSelector(getIsCommonStateLoaded);

  const { isBrowserTabActive } = useInactiveBrowserTab();

  const handlePingOnlineSend = useCallback(
    async (pingReason: 'app_load' | 'focus_tab' | 'interaction') => {
      if (!isAuthenticated || !isCommonStateLoaded) return;

      const eventTs = Date.now();

      if (eventTs - lastPing.current <= PING_INTERVAL) return;

      const pingId = uuidv4();

      if (wsCheckIds && !lastPing.current) wsCheckIds.current = [];

      if (wsCheckIds && lastPing.current) {
        if (wsCheckIds?.current?.length === 1)
          TrackingApi.trackWsLostConnection();

        wsCheckIds.current = [pingId];
      }

      setSessionStorageItem(LAST_ONLINE_TS_STORAGE_KEY, eventTs);

      lastPing.current = eventTs;

      const siteVersion = await AuthApi.getSiteVersion();
      const siteVersionFromStorage = getSessionStorageItem(SITE_VERSION_KEY);

      if (
        siteVersion &&
        siteVersionFromStorage &&
        siteVersion !== siteVersionFromStorage
      ) {
        setSessionStorageItem(SITE_VERSION_KEY, siteVersion);

        if (!isAppLoad.current) {
          window.location?.reload();
        }
      }

      if (siteVersion && !siteVersionFromStorage) {
        setSessionStorageItem(SITE_VERSION_KEY, siteVersion);
      }

      AuthApi.pingOnline({
        siteVersion,
        type: OnlineType.RealOnline,
        ping_id: pingId,
        ping_reason: pingReason,
      });

      if (isAppLoad.current) isAppLoad.current = false;
    },
    [isAuthenticated, isCommonStateLoaded, wsCheckIds]
  );

  const handleInteractionPing = useCallback(
    () => handlePingOnlineSend('interaction'),
    [handlePingOnlineSend]
  );

  const handleTabChange = useCallback(() => {
    if (!isAuthenticated) return;

    if (lastPing.current) {
      lastPing.current = 0;
    }
  }, [isAuthenticated]);

  const handleBeaconOfflineSend = useCallback(() => {
    const { token: accessToken } = getAccessTokenDataFromStorage();

    if (!accessToken) return;

    try {
      const blob = new Blob(
        [
          JSON.stringify({
            offline_reason: 2,
          }),
        ],
        {
          type: 'application/json',
        }
      );

      navigator.sendBeacon(
        `${MirrorService.apiUrl}/ping/offline?token=${accessToken}`,
        blob
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!isBrowserTabActive) {
      handleTabChange();
    }
  }, [handleTabChange, isBrowserTabActive]);

  useEffect(() => {
    if (isBrowserTabActive) {
      handlePingOnlineSend(isAppLoad.current ? 'app_load' : 'focus_tab');
    }
  }, [handlePingOnlineSend, isBrowserTabActive]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeaconOfflineSend);

    return () => {
      window.removeEventListener('beforeunload', handleBeaconOfflineSend);
    };
  }, [handleBeaconOfflineSend]);

  useEffect(() => {
    document.addEventListener('click', handleInteractionPing);
    document.addEventListener('scroll', handleInteractionPing);
    document.addEventListener('mousemove', handleInteractionPing);
    document.addEventListener('keydown', handleInteractionPing);
    document.addEventListener('touchstart', handleInteractionPing);

    return () => {
      document.removeEventListener('click', handleInteractionPing);
      document.removeEventListener('scroll', handleInteractionPing);
      document.removeEventListener('mousemove', handleInteractionPing);
      document.removeEventListener('keydown', handleInteractionPing);
      document.removeEventListener('touchstart', handleInteractionPing);
    };
  }, [handleInteractionPing]);
};
