import { MirrorService } from 'services/MirrorService';
import {
  ConversionEventType,
  GtmConversionEventName,
} from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';
import { getLocalStorageItem } from 'helpers/localStorage';

export const Gtm = {
  init(params: { gtmId: string }) {
    const { gtmId } = params;

    if (!gtmId) {
      return;
    }

    const innerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    const noscriptInner = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    const script = document.createElement('script');
    const noscript = document.createElement('noscript');

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    script.innerHTML = innerScript;
    noscript.innerHTML = noscriptInner;
  },

  mapEventTypeToEventName(eventType: ConversionEventType) {
    switch (eventType) {
      case ConversionEventType.Registration: {
        return GtmConversionEventName.Registration;
      }
      case ConversionEventType.MailConfirm: {
        return GtmConversionEventName.MailConfirm;
      }
      case ConversionEventType.FirstPurchase: {
        return GtmConversionEventName.Sale;
      }
      case ConversionEventType.PurchaseTotal: {
        return GtmConversionEventName.Resale;
      }
      case ConversionEventType.FindLocation: {
        return GtmConversionEventName.CardSubmit;
      }
      case ConversionEventType.UserSpent1cr: {
        return GtmConversionEventName.SpendFirstCredit;
      }
      case ConversionEventType.UserSpent15cr: {
        return GtmConversionEventName.Spend15Credits;
      }
      case ConversionEventType.EngagedUserV1: {
        return GtmConversionEventName.EngagedUserV1;
      }
      case ConversionEventType.EURetention: {
        return GtmConversionEventName.EURetention;
      }
      case ConversionEventType.EU30min: {
        return GtmConversionEventName.EU30min;
      }
      case ConversionEventType.EUSubmit: {
        return GtmConversionEventName.EUSubmit;
      }

      default:
        return null;
    }
  },

  trackEvent(payload: { eventType: ConversionEventType; isRetry: boolean }) {
    const { eventType, isRetry } = payload;
    const eventName = this.mapEventTypeToEventName(eventType);

    if (
      !eventType ||
      !eventName ||
      !MirrorService.gtmId ||
      !Object.values(GtmConversionEventName).includes(eventName)
    )
      return;

    window.dataLayer = window?.dataLayer || [];
    window.dataLayer?.push({ event: eventName });

    if (eventType)
      ConversionsApi.forceServerConversion({ isRetry: !!isRetry, eventType });

    if (getLocalStorageItem('conversions_debug')) {
      // eslint-disable-next-line no-console
      console.log('[GTM]', eventName);
    }
  },
};
