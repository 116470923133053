import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { getIsAuthenticated } from 'store/auth/selectors';

export const PublicRoute = () => {
  const { pathname } = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);

  if (isAuthenticated && pathname === window.location.pathname)
    return <Navigate to="/finder" replace />;

  return <Outlet />;
};
