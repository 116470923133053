import { useCallback, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import { TrackingApi } from 'api/TrackingApi';

const isValidULID = (ulid: string) => {
  return ulid?.length === 26;
};

export const usePopupTracking = () => {
  const popupFlowId = useRef<string | null>(null);

  const interactionUserId = useMemo(
    () => window.location.pathname.split('/').find(isValidULID),
    []
  );

  const trackPopup = useCallback(
    ({
      popupName,
      operation,
      clickDestination,
    }: {
      popupName: PopupName;
      operation: PopupOperation;
      clickDestination?: string;
    }) => {
      if (!popupFlowId.current) {
        popupFlowId.current = uuidv4();
      }

      TrackingApi.trackPopup({
        id: popupFlowId.current,
        name: popupName,
        operation,
        clickDestination,
        interactionUserId,
      }).catch((err) => err);

      if (operation === PopupOperation.Close) {
        popupFlowId.current = null;
      }
    },
    [interactionUserId]
  );

  const trackPopupAsync = useCallback(
    async ({
      popupName,
      operation,
      clickDestination,
    }: {
      popupName: PopupName;
      operation: PopupOperation;
      clickDestination?: string;
    }) => {
      if (!popupFlowId.current) {
        popupFlowId.current = uuidv4();
      }

      await TrackingApi.trackPopup({
        id: popupFlowId.current,
        name: popupName,
        operation,
        clickDestination,
        interactionUserId,
      }).catch((err) => err);

      if (operation === PopupOperation.Close) {
        popupFlowId.current = null;
      }
    },
    [interactionUserId]
  );

  return {
    trackPopup,
    trackPopupAsync,
  };
};
