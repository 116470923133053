import { useCallback } from 'react';

import { MirrorService } from 'services/MirrorService';
import { ConversionEventType } from 'types/enums/ConversionEvent';
import { PartnerType } from 'types/enums/PartnerType';
import { PostbackType } from 'types/enums/PostbackType';
import { ConversionEventData } from 'types/interfaces/ConversionEventData';

import { ConversionsApi } from 'api/ConversionsApi';
import { FacebookPixel } from 'helpers/analytics/fbPixel';
import { Gtm } from 'helpers/analytics/gtm';
import { TikTokPixel } from 'helpers/analytics/tikTokPixel';

//  ! do not use useEffect here
export const useConversionTracking = () => {
  const sendFBConversion = useCallback(
    (event: { data: ConversionEventData; isRetry?: boolean }) => {
      const isRetry = !!event?.isRetry;
      const conversionPixelId = event.data?.pixel_id;
      const fbParallelNeedSend = event.data?.fb_parallel_need_send;

      const isFbConversion =
        event.data?.partner_type === PartnerType.Facebook ||
        event.data?.postback_type === PostbackType.Facebook;
      const isAffiliateConversion =
        event.data?.partner_type === PartnerType.Affiliate ||
        event.data?.postback_type === PostbackType.Affiliate;

      // ? disable tracking for iframe)
      if (window.location !== window.parent?.location) return;

      // ? disable tracking for non fb conversions
      if (
        !(isFbConversion && conversionPixelId) &&
        !(fbParallelNeedSend && MirrorService.facebookPixelId)
      )
        return;

      // ? init custom fb pixel id
      if (isFbConversion && conversionPixelId) {
        FacebookPixel.init({ pixelId: conversionPixelId });
      }

      FacebookPixel.trackEvent({
        isRetry,
        eventData: event.data,
        withApi: isFbConversion || isAffiliateConversion,
        singlePixelId: fbParallelNeedSend ? null : String(conversionPixelId),
      });
    },
    []
  );

  const sendTikTokConversion = useCallback(
    (event: { data: ConversionEventData; isRetry?: boolean }) => {
      const conversionPixelId = event?.data?.pixel_id;
      const eventID = event?.data?.event_id;
      const isRetry = !!event?.isRetry;

      // ? disable tracking for iframe)
      if (window.location !== window.parent?.location) return;

      if (!conversionPixelId || !eventID) return;

      if (!window.tikTokInitialized) {
        TikTokPixel.init({ newPixelId: conversionPixelId });

        window.tikTokInitialized = true;
      }

      TikTokPixel.trackEvent({ eventData: event.data, isRetry });
    },
    []
  );

  const sendGTMConversion = useCallback(
    (event: { data: ConversionEventData; isRetry?: boolean }) => {
      const isRetry = !!event?.isRetry;
      const eventType = event.data?.event_type as ConversionEventType;

      // ? disable tracking for iframe)
      if (window.location !== window.parent?.location) return;

      Gtm.trackEvent({ eventType, isRetry });
    },
    []
  );

  const conversionEventHandler = useCallback(
    (event: { data: ConversionEventData }) => {
      const partnerType = event?.data?.partner_type;

      sendFBConversion(event);

      if (partnerType === PartnerType.GTM) {
        sendGTMConversion(event);

        return;
      }

      if (partnerType === PartnerType.TikTok) {
        sendTikTokConversion(event);
      }
    },
    [sendFBConversion, sendGTMConversion, sendTikTokConversion]
  );

  const sendSingleConversionHandler = useCallback(
    async ({
      eventType,
      isRetry,
    }: {
      eventType: ConversionEventType;
      isRetry?: boolean;
    }) => {
      const eventData = await ConversionsApi.createConversionData(eventType);

      sendFBConversion({ data: eventData, isRetry });

      if (eventData.postback_type === PostbackType.GoogleAds) {
        sendGTMConversion({ data: eventData, isRetry });

        return;
      }

      if (eventData.postback_type === PostbackType.TikTok) {
        sendTikTokConversion({ data: eventData, isRetry });
      }
    },
    [sendFBConversion, sendGTMConversion, sendTikTokConversion]
  );

  return {
    conversionEventHandler,
    sendSingleConversionHandler,
  };
};
