import { createSelector } from '@reduxjs/toolkit';

import { PackageGroupType } from 'types/enums/payment/PackageGroupType';
import { Package } from 'types/interfaces/payment/Package';

import { RootState } from 'store/rootReducer';

const getPackageWithBiggestIncome = (packages: Package[]) => {
  if (!packages.length) return [];
  let mostIncomePackage = packages[0];

  packages.forEach((packItem) => {
    if (packItem.credits_buy > mostIncomePackage.credits_buy)
      mostIncomePackage = packItem;
  });

  return [mostIncomePackage];
};

export const getPaymentOrderInfoSelector = (state: RootState) =>
  state.payment.paymentOrderInfo;

export const getPaymentErrorSelector = (state: RootState) =>
  state.payment.paymentError;

export const getVerifyUrlSelector = (state: RootState) =>
  state.payment.verifyUrl;

export const getCreditPackages = (state: RootState) =>
  state.payment.creditPackages;

export const getGiftPackages = (state: RootState) => state.payment.giftPackages;

export const getSubscriptionPackages = (state: RootState) =>
  state.payment.recurrentPackages;

export const getActiveCreditPackage = (state: RootState) =>
  state.payment.creditPackages.find((pack) => pack.active) ?? null;

export const getActiveGiftPackage = (state: RootState) =>
  state.payment.giftPackages.find((pack) => pack.active) ?? null;

export const getActiveSubscriptionPackage = (state: RootState) =>
  state.payment.recurrentPackages.find((pack) => pack.active) ?? null;

export const getCreditBalanceSelector = (state: RootState) =>
  state.payment.credits.amount;

export const getCreditsCurrencySelector = (state: RootState) =>
  state.payment.credits.currency;

export const getIsRefillEnabledSelector = (state: RootState) =>
  state.payment.creditsInfo.isRefillEnabled;

export const getCountOfPaysSelector = (state: RootState) =>
  state.payment.creditsInfo.countOfPays;

export const getActiveRefillPackage = (state: RootState) =>
  state.payment.creditsInfo.refillPackage;

export const getSubscriptionInfoSelector = (state: RootState) =>
  state.payment.subscriptionInfo;

export const getIsUserSpentFreeCreditsSelector = (state: RootState) =>
  state.payment.credits.spend_amount === 20;

export const getSubscriptionUpSalePackageSelector = createSelector(
  getSubscriptionPackages,
  (packages) => packages.find((pack) => pack.is_default)
);

export const getIsInitPackageSelector = createSelector(
  getCreditPackages,
  (packages) => packages.length === 1
);

export const getInitPackageSelector = createSelector(
  getCreditPackages,
  (packages) => {
    if (packages.length === 1) return packages[0];

    return null;
  }
);

export const getIsUserBoughtInitPackageSelector = createSelector(
  getCreditPackages,
  (packages) => packages.length > 1
);

export const getIsUserHasSubscriptionSelector = createSelector(
  getSubscriptionInfoSelector,
  (info) => info.can_change_subscription !== null
);

export const getIsUserCanChangeSubscriptionSelector = createSelector(
  getSubscriptionInfoSelector,
  getIsUserHasSubscriptionSelector,
  (info, hasSubscription) => !hasSubscription || info.can_change_subscription
);

export const getPaymentPackageByIdSelector = (id: string) =>
  createSelector(
    getCreditPackages,
    getGiftPackages,
    getSubscriptionPackages,
    (creditPackages, giftPackages, subscriptionPackages) =>
      creditPackages.find((pack) => pack.id === id) ||
      giftPackages.find((pack) => pack.id === id) ||
      subscriptionPackages.find((pack) => pack.id === id)
  );

export const getUpgradeBalancePackages = (
  packageType: PackageGroupType,
  minPrice: number
) =>
  createSelector(
    getCreditPackages,
    getGiftPackages,
    (creditPackages, giftPackages) => {
      // ? init package should be always first purchase
      if (creditPackages.length === 1) {
        return creditPackages;
      }

      if (packageType === PackageGroupType.Gift) {
        const finalGiftPackages = giftPackages.filter(
          (packItem) => packItem.credits_buy >= minPrice
        );

        return finalGiftPackages.length
          ? finalGiftPackages
          : getPackageWithBiggestIncome(giftPackages);
      }

      return creditPackages;
    }
  );

export const getActiveUpgradeBalancePackages = (
  packageType: PackageGroupType
) =>
  createSelector(
    getActiveCreditPackage,
    getActiveGiftPackage,
    (activeCreditPackage, activeGiftPackage) => {
      if (packageType === PackageGroupType.Gift) return activeGiftPackage;

      return activeCreditPackage;
    }
  );
