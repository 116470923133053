const MONETIZATION_PAGES_PATHES = ['/upgrade', '/payment'];

export const isPrelandPage = (pathname: string) => {
  return pathname.includes('/landing/lp');
};

export const isMainPage = (pathname: string) => pathname === '/';

export const isLoginPage = (pathname: string) => pathname.includes('login');

export const prelandIdFormPathname = (pathname: string) => {
  let prelandId = pathname?.split?.('landing/lp')?.[1]?.match?.(/\d+/)?.[0];

  if (!prelandId) {
    prelandId = '99';
  }

  if (pathname.includes('/entry/click')) {
    prelandId = '98';
  }

  if (isMainPage(pathname)) {
    prelandId = '0';
  }

  if (isLoginPage(pathname)) {
    prelandId = '96';
  }
  return Number(prelandId);
};

export const isTermsOrPolicyPage = (pathname: string) =>
  pathname.match(/(\/terms)|(\/(.*)?policy)/gi);

export const isMonetizationPage = (pathname: string) => {
  const reg = new RegExp(MONETIZATION_PAGES_PATHES.join('|'), 'gi');
  return pathname.match(reg);
};

export const isDialogPage = (pathname: string) => {
  return pathname.includes('/dialogs/');
};

export const isMailsPage = (pathname: string) => {
  return pathname.includes('/mails/');
};

export const isDialogsPage = (pathname: string) => {
  return pathname.match(/\/dialogs(\/\d+)?/);
};

export const isWizardPage = (pathname: string) => {
  return pathname.includes('/wizard/wp');
};

export const LANDING_PATHNAME_REGEX = /\/landing\/lp\d{1,2}-.{1,7}/;
export const WIZARD_PATHNAME_REGEX = /\/wizard\/wp\d{1,2}-.{1,7}/;

export const getLandingIdFromUrl = (pathname: string) => {
  if (pathname.match(LANDING_PATHNAME_REGEX)) {
    const splitedUrl = pathname.split('/');
    return parseInt(splitedUrl[2].replace('lp', ''), 10);
  }
  return null;
};

export const getWizardIdFromUrl = (pathname: string) => {
  if (pathname.match(WIZARD_PATHNAME_REGEX)) {
    const splitedUrl = pathname.split('/');
    return parseInt(splitedUrl[2].replace('wp', ''), 10);
  }
  return 99;
};

export const getStepFromUrl = (pathname: string) => {
  const splitUrl = pathname.split('/');
  return parseInt(splitUrl[splitUrl.length - 1], 10);
};

export const getSafeStepFromUrl = (stepNum: number, stepsLength: number) => {
  if (!stepNum) {
    return 1;
  }

  if (stepNum > stepsLength) {
    return stepsLength;
  }

  return stepNum;
};
