import { useEffect, useState } from 'react';

import { MirrorService } from 'services/MirrorService';

import { TrackingApi } from 'api/TrackingApi';
import { FacebookPixel } from 'helpers/analytics/fbPixel';
import { Gtm } from 'helpers/analytics/gtm';
import { setBuildVersionToSessionStorage, useI18n } from 'hooks/useI18n';
import { useInitialId } from 'hooks/useInitialId';
import { useSessionStartTrack } from 'hooks/useSessionStartTrack';

export const useInitializeApp = () => {
  const { getInitialId, setInitialIdToCookie } = useInitialId();
  const { initI18n } = useI18n();
  const { trackStartSession } = useSessionStartTrack();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      if (MirrorService.gtmId) Gtm.init({ gtmId: MirrorService.gtmId });
      if (MirrorService.facebookPixelId)
        FacebookPixel.init({ pixelId: MirrorService.facebookPixelId });

      try {
        const initialId = await getInitialId();

        setInitialIdToCookie(initialId);
      } catch (err: any) {
        TrackingApi.trackError({
          type: 'error',
          message: `set initial_id fail: ${err?.message}`,
        });
      }

      const newTranslationsBuildVersion = await trackStartSession(true);

      if (newTranslationsBuildVersion) {
        setBuildVersionToSessionStorage(newTranslationsBuildVersion);
      }

      await initI18n();

      setIsInitialized(true);

      try {
        TrackingApi.trackAppLoadEnd();
      } catch (error) {
        console.error(error);
      }
    };

    if (!isInitialized) initialize();
  }, [
    getInitialId,
    initI18n,
    isInitialized,
    setInitialIdToCookie,
    trackStartSession,
  ]);

  return {
    isInitialized,
  };
};
