import React, { ReactNode } from 'react';

import { TrackingApi } from 'api/TrackingApi';

import { UnhandledError } from 'components/shared/UnhandledError';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

const ERRORS_LIST_TO_IGNORE = [
  'Loading chunk', // ? User Network or Deploy issues
  'Loading CSS chunk', // ? User Network or Deploy issues
  'Network Error', // ? User Network issues
];

const shouldErrorBeTracked = (errorMessage?: any) => {
  if (!errorMessage) return true;

  return !ERRORS_LIST_TO_IGNORE.find(
    (errorToIgnore) => errorMessage?.includes(errorToIgnore)
  );
};

export class GlobalErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (
      shouldErrorBeTracked(error?.message) &&
      process.env.NODE_ENV !== 'development'
    ) {
      TrackingApi.trackError({
        type: 'error',
        message: `GlobalErrorBoundary: ${error?.message}`,
        params: {
          stack: error?.message?.includes('Failed to execute')
            ? errorInfo?.componentStack || error?.stack
            : error?.stack,
        },
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <UnhandledError />;
    }

    return children;
  }
}
