import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PopupName } from 'types/enums/Popup';

import { Funnel } from 'helpers/funnels/funnels';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';

// TODO move to helpers, create popup queue and do this check for all existing popups
const LAST_SYSTEM_POPUP_CLOSE_AT_STORAGE_KEY = 'system_popup_close_at';

export const getIsSystemPopupQueueLocked = (delay: number) => {
  const lastCloseAt = getSessionStorageItem(
    LAST_SYSTEM_POPUP_CLOSE_AT_STORAGE_KEY
  );

  if (!lastCloseAt) false;

  try {
    return Number(lastCloseAt) + delay > Date.now();
  } catch (error) {
    return false;
  }
};

export enum SystemPopupTypes {
  LowBalance = 1,
  InitCredits = 2,
  MailConfirmAfterReg = 3,
  ReportUser = 4,
  PresentSent = 5,
  PromotePWA = 6,
  MailConfirmAfterProfile = 7,
  OnlineOnClick = 9,
  EmailUnsubscribe = 10,
  DisappearingMessage = 11,
}

export interface LowBalancePopupParams {
  funnel: Funnel;
  contactId: string;
  name?: string;
  photoUrl?: string;
  url?: string;
  min_income?: number;
  a_gift_id?: number;
  type?: 'media' | 'mailRead';
}

export interface InitCreditsPopupParams {
  username?: string;
}

export interface MailConfirmRegPopupParams {
  isRetry: boolean;
}

export interface ReportUserPopupParams {
  userId: string;
}

export interface PresentSentPopupParams {
  contactName: string;
  presentSrc: string;
}

export interface PWAPopupParams {
  popupName: PopupName;
  isWizard: boolean;
}

type SystemPopupParams =
  | LowBalancePopupParams
  | MailConfirmRegPopupParams
  | InitCreditsPopupParams
  | ReportUserPopupParams
  | PresentSentPopupParams
  | PWAPopupParams;

interface SystemPopupState {
  type: SystemPopupTypes | null;
  params: SystemPopupParams | null;
}

const initialState: SystemPopupState = {
  type: null,
  params: null,
};

const systemPopupSlice = createSlice({
  name: 'systemPopup',
  initialState,
  reducers: {
    setActiveSystemPopup(
      state,
      action: PayloadAction<{
        type: SystemPopupTypes;
        params?: SystemPopupParams;
      }>
    ) {
      state.type = action.payload.type;
      state.params = action.payload.params || null;
    },

    closeSystemPopup(state) {
      state.type = null;
      state.params = null;

      setSessionStorageItem(LAST_SYSTEM_POPUP_CLOSE_AT_STORAGE_KEY, Date.now());
    },

    resetState() {
      return initialState;
    },
  },
});

export const { setActiveSystemPopup, closeSystemPopup, resetState } =
  systemPopupSlice.actions;

export const systemPopup = systemPopupSlice.reducer;
