import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { getIsAuthenticated } from 'store/auth/selectors';
import { init } from 'store/common/thunks';

export const PrivateRoute = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(init());
    }
  }, [dispatch, isAuthenticated]);

  if (!isAuthenticated) return <Navigate to="/login" replace />;

  return <Outlet />;
};
