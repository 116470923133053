import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export const useDeleteQueryByName = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const deleteQueryByName = useCallback(
    (queryKeyToDelete: string) => {
      const params = queryString.parse(search);

      delete params[queryKeyToDelete];

      navigate({ search: queryString.stringify(params) }, { replace: true });
    },
    [navigate, search]
  );

  return { deleteQueryByName };
};
