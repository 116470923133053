import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

interface SiteConfig {
  id: number;
  groupId: number;
  domain: AppDomain;
  gtm: Partial<Record<AppEnv, string>>;
  supportPhone?: string;
  cookieCbid: Partial<Record<AppEnv, string>>;
  clarityId: Partial<Record<AppEnv, string>>;
  facebookPixelId: Partial<Record<AppEnv, string>>;
}

export const SITES_CONFIGS: Record<string, SiteConfig> = {
  MARIADATES: {
    id: 1,
    groupId: 1,
    domain: AppDomain.MariaDates,
    gtm: {},
    supportPhone: '+1 (510) 726-1371',
    cookieCbid: {},
    clarityId: {
      [AppEnv.Prod]: 'q1ibbqbejf',
    },
    facebookPixelId: {
      [AppEnv.Prod]: '4060841014139542',
    },
  },
  PLACEFORTALK: {
    id: 2,
    groupId: 1,
    domain: AppDomain.PlaceForTalk,
    gtm: {
      [AppEnv.Prod]: 'GTM-5H787HM6',
      [AppEnv.Stage]: 'GTsssMssss',
    },
    supportPhone: '+1 (520) 655-7520',
    cookieCbid: {
      [AppEnv.Prod]: '9afc6f36-1e6d-4ab4-91d9-e094a073f73c',
    },
    clarityId: {
      [AppEnv.Prod]: 'q1ebuo1209',
    },
    facebookPixelId: {
      [AppEnv.Prod]: '4060841014139542',
      [AppEnv.Stage]: '4060841014139542',
    },
  },
  LATINESSE: {
    id: 3,
    groupId: 1,
    domain: AppDomain.LatinEsse,
    gtm: {
      [AppEnv.Prod]: 'GTM-TJH2FS33',
    },
    supportPhone: '+1 (430) 234-3085',
    cookieCbid: {},
    clarityId: {
      [AppEnv.Prod]: 'q4ihasapy5',
    },
    facebookPixelId: {},
  },
  EMBERLOVE: {
    id: 6,
    groupId: 1,
    domain: AppDomain.EmberLove,
    gtm: {
      [AppEnv.Prod]: 'GTM-PCHG6GJX',
    },
    supportPhone: '+1 (646) 506-4384',
    cookieCbid: {
      [AppEnv.Prod]: 'cbf3817e-d933-46c3-b547-a7f528e5b250',
    },
    clarityId: {
      [AppEnv.Prod]: 'q1ec0gp6yc',
    },
    facebookPixelId: {
      [AppEnv.Prod]: '4060841014139542',
    },
  },
};

export const DEFAULT_SITE_ID = SITES_CONFIGS.MARIADATES.id;
export const DEFAULT_DEV_SITE_DOMAIN = 'spiddy.co';
export const DEFAULT_PRODUCTION_SITE_DOMAIN = SITES_CONFIGS.MARIADATES.domain;
