export enum Pages {
  Home = '/',
  Login = '/login',
  Landings = '/landing/*',
  Wizards = '/wizard/*',

  Finder = '/finder',
  Dialogs = '/dialogs',
  DialogsTread = '/dialogs/:contactId',
  Mails = '/mails',
  MailsTread = '/mails/:contactId',
  UserProfile = '/user/:contactId',
  FullMediaScreen = '/:page/:contactId/media',
  PresentsGuide = '/presents/guide/:contactId',
  PresentsSend = '/presents/send/:contactId',

  UpgradeBalance = '/upgrade/balance',
  UpgradeSubscription = '/upgrade/subscription',
  PaymentFail = '/payment/fail',
  PaymentSuccess = '/payment/success',
  PaymentByCard = '/payment/card/:packageId',
  PaymentByOneClick = '/payment/one-click/:packageId',

  CheckInbox = '/check-inbox',
  MyProfile = '/my-profile',
  EditProfile = '/my-profile/edit',
  HiddenDialogs = '/my-profile/hidden',
  ContactUs = '/my-profile/contact-us',
  Pricing = '/my-profile/pricing',
  HelpCenter = '/my-profile/help-center',
  BlockedProfiles = '/my-profile/blocked',
  BlockedAndHidden = '/my-profile/blocked-and-hidden',
  Settings = '/my-profile/settings',
  ChangeEmailSettings = '/my-profile/settings/account/change-email',
  ChangePasswordSettings = '/my-profile/settings/account/change-password',
  AccountSettings = '/my-profile/settings/account',
  NotificationsSettings = '/my-profile/settings/notifications',
  AccountDeleteSettings = '/my-profile/settings/delete',
  EmailUnsubscribe = '/my-profile/settings/email-unsubscribe',

  Terms = '/terms',
  PrivacyPolicy = '/privacy-policy',
  RefundPolicy = '/refund-policy',
  DisclosuresDisclaimers = '/disclosures-disclaimers',
  AntiScamPolicy = '/anti-scam-policy',
  AntiSpamPolicy = '/anti-spam-policy',
  TrackingPolicy = '/tracking-policy',
  ConsentPolicy = '/consent-policy',
  ComplaintPolicy = '/complaint-policy',
  ContentRemovalPolicy = '/content-removal-policy',
  DataPrivacy = '/data-privacy',

  ForgotPassword = '/forgot-password',
  NewPassword = '/new-password/:code',
}
