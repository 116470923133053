export const COLOR_TAGS_BACKGROUNDS: string[] = [
  '#FFE2DB',
  '#DBFFE5',
  '#DBEEFF',
  '#E7DBFF',
  '#FFDBFE',
];

export const TRACK_START_APP_SESSION_STORAGE_KEY = 'TRACK_START_APP';
export const TRANSLATIONS_BUILD_SESSION_STORAGE_KEY =
  'TRANSLATIONS_BUILD_VERSION';

export const MAX_PROFILE_PHOTOS_COUNT = 10;

export const IS_DISABLED_COOKIE_BANNER = 'cooke_b_d';

export const PRODUCT_PRICING = {
  sendMessage: 2,
  sendSticker: 10,
  sendMail: 20,
  readMail: 20,

  sendPhoto: 30,
  openPhoto: 30,
};
