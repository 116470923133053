import React, { memo } from 'react';

import { MirrorService } from 'services/MirrorService';
import {
  INotification,
  NotificationExtra,
} from 'types/interfaces/Notifications';

import { getPhotoPlaceholder } from 'helpers/photoPlaceholder';

import { UserAvatar } from 'components/shared/UserAvatar';

import { NotificationBadge } from '../NotificationBadge';

import css from './notificationToast.module.sass';

export interface NotificationToastProps {
  notification: INotification & NotificationExtra;
  isEnabledSound: boolean;
  btnText?: string;
  onClick?: () => void;
}

export const NotificationToast: React.FC<NotificationToastProps> = memo(
  ({ notification, btnText, onClick }) => {
    return (
      <div className={css.root} onClick={onClick}>
        <UserAvatar
          url={
            MirrorService.resolveImagePath(
              notification?.sender?.main_photo?.profile_url
            ) ?? getPhotoPlaceholder(notification?.sender?.gender)
          }
          size="50px"
          badge={<NotificationBadge type={notification.type} />}
        />

        <div className={css.content}>
          <p className={css.title}>{notification.title}</p>
          <p className={css.subtitle}>{notification.description}</p>
        </div>

        {!!btnText && <div className={css.btn}>{btnText}</div>}
      </div>
    );
  }
);
