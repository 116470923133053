import React, { FC, useEffect } from 'react';

import { PopupName, PopupOperation } from 'types/enums/Popup';

import { usePopupTracking } from 'hooks/tracking/usePopupTracking';
import { useDeleteQueryByName } from 'hooks/useDeleteQueryByName';
import { QueryKeys } from 'hooks/useSystemSearchQueries';

import mailUnsubscribe from 'assets/mail-unsubscribed.png';

import { BaseButton } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';

import css from './emailUnsubscribePopup.module.sass';

interface Props {
  onClose: () => void;
}

export const EmailUnsubscribePopup: FC<Props> = ({ onClose }) => {
  const { trackPopup } = usePopupTracking();
  const { deleteQueryByName } = useDeleteQueryByName();

  const handleClose = () => {
    onClose();

    deleteQueryByName(QueryKeys.StarterPopup);

    trackPopup({
      popupName: PopupName.EmailUnsubscribe,
      operation: PopupOperation.Click,
    });
  };

  useEffect(() => {
    trackPopup({
      popupName: PopupName.EmailUnsubscribe,
      operation: PopupOperation.Show,
    });
  }, [trackPopup]);

  return (
    <Popup>
      <div className={css.root}>
        <img
          className={css.image}
          src={mailUnsubscribe}
          alt="mailUnsubscribe"
        />
        <div className={css.textWrapper}>
          <p className={css.title}>You’ve Unsubscribed</p>
          <p>You will no longer receive our emails</p>
        </div>

        <div className={css.buttonWrapper}>
          <BaseButton className={css.button} onClick={handleClose}>
            Close
          </BaseButton>
        </div>
      </div>
    </Popup>
  );
};
