import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';
import { getScreenOrientationWithSizes } from 'helpers/device';
import { useAppRouteEvents } from 'hooks/useAppRouteEvents';

export const usePageLoad = () => {
  const handleTrackPageLoad = useCallback(
    ({ duration }: { duration: number }) => {
      TrackingApi.trackPageLoad({
        url: window.location.href,
        duration: Math.round(duration),
        screen: getScreenOrientationWithSizes(),
      });
    },
    []
  );

  useAppRouteEvents({
    onLoadEnd: handleTrackPageLoad,
  });
};
