import * as Sentry from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { clarity } from 'react-microsoft-clarity';

import { MirrorService } from 'services/MirrorService';

import { setXPrevUrl, setXRefererUrl } from 'api/httpClient';
import { TrackingApi } from 'api/TrackingApi';
import { setTrackingCookiesFromUrl } from 'helpers/analytics/trackingCookies';

import { AppContainer } from './AppContainer';

import 'globalthis/auto';
import 'context-filter-polyfill';
import 'resize-observer-polyfill';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/main.sass';

MirrorService.init();

Sentry.init({
  dsn: 'https://9a3194eec46d4b52394fdedc5cc58006@o4506705060888576.ingest.us.sentry.io/4507854472085504',

  integrations: [Sentry.browserTracingIntegration()],
  ignoreErrors: [
    'UET is not defined',
    'Request aborted',
    'Network',
    'Failed to fetch',
    'Loading CSS chunk',
    'Load failed',
  ],

  sampleRate: 0.04,
  tracesSampleRate: 0.04,

  denyUrls: [/spiddy.co/i],
  enabled: MirrorService.isProd,
});

if (MirrorService.clarityId) {
  clarity.init(MirrorService.clarityId);
}

setXPrevUrl(document.referrer);
setXRefererUrl(window.location.href);

setTrackingCookiesFromUrl();

TrackingApi.trackReactChunkLoadEnd();

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(<AppContainer />);
