import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';

import { IS_DISABLED_COOKIE_BANNER } from 'helpers/constants';
import { getLocalStorageItem } from 'helpers/localStorage';

export const useCookieSettings = (isAuthenticated: boolean) => {
  const { pathname } = useLocation();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const isFeatureDisabled = getLocalStorageItem(IS_DISABLED_COOKIE_BANNER);

    if (
      pathname === '/' &&
      !isFeatureDisabled &&
      !isInitialized &&
      !isAuthenticated &&
      MirrorService.cookieCbid
    ) {
      setIsInitialized(true);

      const innerScript = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          ad_personalization: 'denied',
          ad_storage: 'denied',
          ad_user_data: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          security_storage: 'granted',
          wait_for_update: 500,
        });
        gtag('set', 'ads_data_redaction', true);
        gtag('set', 'url_passthrough', false);
      `;
      const script = document.createElement('script');
      const settingScript = document.createElement('script');

      settingScript.setAttribute('data-cookieconsent', 'ignore');
      settingScript.innerHTML = innerScript;

      script.setAttribute('id', 'CookieBot');
      script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
      script.setAttribute('data-cbid', MirrorService.cookieCbid);
      script.setAttribute('type', 'text/javascript');

      document.head.insertBefore(script, document.head.childNodes[0]);
      document.head.insertBefore(settingScript, document.head.childNodes[0]);
    }
  }, [isAuthenticated, isInitialized, pathname]);
};
