import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { Funnel as IFunnel } from 'helpers/funnels/funnels';
import { assertString } from 'helpers/utils/checkType';

export enum QueryKeys {
  LoginToken = 'login_token',
  InitialId = 'initial_id',
  PartnerId = 'partner_id',
  PartnerLoginId = 'partner_login_id',
  UserId = 'user_id',
  ClickId = 'click_id',
  Funnel = 'funnel',
  ExtEmail = 'ext',
  Fbclid = 'fbclid',
  Ttclid = 'ttclid',
  Twclid = 'twclid',
  Gclid = 'gclid',
  Wbraid = 'wbraid',
  Gbraid = 'gbraid',
  SuccessUrlPathname = 'success_url',
  FailUrlPathname = 'fail_url',
  ActiveStickerPackId = 'a_s_pack_id',
  ActiveGiftId = 'a_gift_id',
  ActivePresentId = 'a_present_id',
  MinCreditsIncome = 'min_income',
  StarterPopup = 'starter_popup',
  ActiveTab = 'tab',
  PackageId = 'package_id',
  ChangeCard = 'change_card',
  SupportWidget = 'support_w',
  ChatRequestId = 'chat_request_id',
  MessageId = 'message_id',
  SessionId = 'session_id',
  FunnelProfileId = 'funnel_profile_id',
  EventDataId = 'event_data_id',
  DeliveryId = 'delivery_id',
  MediaSrc = 'media_src',
  UserName = 'username',
}

export const useSystemSearchQueries = () => {
  const { search } = useLocation();

  const {
    [QueryKeys.LoginToken]: loginToken,
    [QueryKeys.InitialId]: initialId,
    [QueryKeys.PartnerLoginId]: partnerLoginId,
    [QueryKeys.PartnerId]: partnerId,
    [QueryKeys.UserId]: userId,
    [QueryKeys.ClickId]: clickId,
    [QueryKeys.Funnel]: funnel,
    [QueryKeys.ExtEmail]: extEmail,
    [QueryKeys.Fbclid]: fbclid,
    [QueryKeys.Ttclid]: ttclid,
    [QueryKeys.Twclid]: twclid,
    [QueryKeys.Gclid]: gclid,
    [QueryKeys.Wbraid]: wbraid,
    [QueryKeys.Gbraid]: gbraid,
    [QueryKeys.SuccessUrlPathname]: successUrlPathname,
    [QueryKeys.FailUrlPathname]: failUrlPathname,
    [QueryKeys.ActiveStickerPackId]: activeStickerPackId,
    [QueryKeys.ActiveGiftId]: activeGiftId,
    [QueryKeys.ActivePresentId]: activePresentId,
    [QueryKeys.MinCreditsIncome]: minCreditsIncome,
    [QueryKeys.StarterPopup]: starterPopup,
    [QueryKeys.ActiveTab]: activeTab,
    [QueryKeys.PackageId]: packageId,
    [QueryKeys.ChangeCard]: changeCard,
    [QueryKeys.SupportWidget]: supportWidget,
    [QueryKeys.ChatRequestId]: chatRequestId,
    [QueryKeys.MessageId]: messageId,
    [QueryKeys.EventDataId]: eventDataId,
    [QueryKeys.DeliveryId]: deliveryId,
    [QueryKeys.MediaSrc]: mediaSrc,
    [QueryKeys.UserName]: userName,
  } = useMemo(() => queryString.parse(search), [search]);

  const autoLoginQuery = useMemo(() => {
    return {
      key: QueryKeys.LoginToken,
      value: assertString(loginToken) ? loginToken : null,
    };
  }, [loginToken]);

  const initialIdQuery = useMemo(() => {
    return {
      key: QueryKeys.InitialId,
      value: assertString(initialId) ? initialId : null,
    };
  }, [initialId]);

  const partnerIdQuery = useMemo(() => {
    return {
      key: QueryKeys.PartnerId,
      value: assertString(partnerId) ? partnerId : null,
    };
  }, [partnerId]);

  const partnerLoginIdQuery = useMemo(() => {
    return {
      key: QueryKeys.PartnerLoginId,
      value: assertString(partnerLoginId) ? partnerLoginId : null,
    };
  }, [partnerLoginId]);

  const userIdQuery = useMemo(() => {
    return {
      key: QueryKeys.UserId,
      value: assertString(userId) ? userId : null,
    };
  }, [userId]);

  const clickIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ClickId,
      value: assertString(clickId) ? clickId : null,
    };
  }, [clickId]);

  const activeTabQuery = useMemo(() => {
    return {
      key: QueryKeys.ActiveTab,
      value: assertString(activeTab) ? activeTab : null,
    };
  }, [activeTab]);

  const funnelQuery = useMemo(() => {
    return {
      key: QueryKeys.Funnel,
      value: (assertString(funnel) ? funnel : null) as IFunnel | null,
    };
  }, [funnel]);

  const extEmailQuery = useMemo(() => {
    return {
      key: QueryKeys.ExtEmail,
      value: assertString(extEmail) ? extEmail : null,
    };
  }, [extEmail]);

  const fbclidQuery = useMemo(() => {
    return {
      key: QueryKeys.Fbclid,
      value: assertString(fbclid) ? fbclid : null,
    };
  }, [fbclid]);

  const ttclidQuery = useMemo(() => {
    return {
      key: QueryKeys.Ttclid,
      value: assertString(ttclid) ? ttclid : null,
    };
  }, [ttclid]);

  const twclidQuery = useMemo(() => {
    return {
      key: QueryKeys.Twclid,
      value: assertString(twclid) ? twclid : null,
    };
  }, [twclid]);

  const gclidQuery = useMemo(() => {
    return {
      key: QueryKeys.Gclid,
      value: assertString(gclid) ? gclid : null,
    };
  }, [gclid]);

  const wbraidQuery = useMemo(() => {
    return {
      key: QueryKeys.Wbraid,
      value: assertString(wbraid) ? wbraid : null,
    };
  }, [wbraid]);

  const gbraidQuery = useMemo(() => {
    return {
      key: QueryKeys.Gbraid,
      value: assertString(gbraid) ? gbraid : null,
    };
  }, [gbraid]);

  const successUrlPathnameQuery = useMemo(() => {
    return {
      key: QueryKeys.SuccessUrlPathname,
      value: assertString(successUrlPathname) ? successUrlPathname : null,
    };
  }, [successUrlPathname]);

  const failUrlPathnameQuery = useMemo(() => {
    return {
      key: QueryKeys.FailUrlPathname,
      value: assertString(failUrlPathname) ? failUrlPathname : null,
    };
  }, [failUrlPathname]);

  const activeStickerPackIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ActiveStickerPackId,
      value: assertString(activeStickerPackId) ? activeStickerPackId : null,
    };
  }, [activeStickerPackId]);

  const activeGiftIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ActiveGiftId,
      value: assertString(activeGiftId) ? activeGiftId : null,
    };
  }, [activeGiftId]);

  const activePresentIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ActivePresentId,
      value: assertString(activePresentId) ? activePresentId : null,
    };
  }, [activePresentId]);

  const minCreditsIncomeQuery = useMemo(() => {
    return {
      key: QueryKeys.MinCreditsIncome,
      value: assertString(minCreditsIncome) ? minCreditsIncome : null,
    };
  }, [minCreditsIncome]);

  const starterPopupQuery = useMemo(() => {
    return {
      key: QueryKeys.StarterPopup,
      value: assertString(starterPopup) ? starterPopup : null,
    };
  }, [starterPopup]);

  const packageIdQuery = useMemo(() => {
    return {
      key: QueryKeys.PackageId,
      value: assertString(packageId) ? packageId : null,
    };
  }, [packageId]);

  const changeCardQuery = useMemo(() => {
    return {
      key: QueryKeys.ChangeCard,
      value: assertString(changeCard) ? changeCard : null,
    };
  }, [changeCard]);

  const supportWidgetQuery = useMemo(() => {
    return {
      key: QueryKeys.SupportWidget,
      value: assertString(supportWidget) ? supportWidget : null,
    };
  }, [supportWidget]);

  const chatRequestIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ChatRequestId,
      value: assertString(chatRequestId) ? chatRequestId : null,
    };
  }, [chatRequestId]);

  const messageIdQuery = useMemo(() => {
    return {
      key: QueryKeys.MessageId,
      value: assertString(messageId) ? messageId : null,
    };
  }, [messageId]);

  const eventDataIdQuery = useMemo(() => {
    return {
      key: QueryKeys.EventDataId,
      value: assertString(eventDataId) ? eventDataId : null,
    };
  }, [eventDataId]);

  const deliveryIdQuery = useMemo(() => {
    return {
      key: QueryKeys.DeliveryId,
      value: assertString(deliveryId) ? deliveryId : null,
    };
  }, [deliveryId]);

  const mediaSrcQuery = useMemo(() => {
    return {
      key: QueryKeys.MediaSrc,
      value: assertString(mediaSrc) ? mediaSrc : null,
    };
  }, [mediaSrc]);

  const userNameQuery = useMemo(() => {
    return {
      key: QueryKeys.UserName,
      value: assertString(userName) ? userName : null,
    };
  }, [userName]);

  return {
    autoLoginQuery,
    initialIdQuery,
    partnerIdQuery,
    partnerLoginIdQuery,
    userIdQuery,
    clickIdQuery,
    funnelQuery,
    extEmailQuery,
    fbclidQuery,
    ttclidQuery,
    twclidQuery,
    gclidQuery,
    wbraidQuery,
    gbraidQuery,
    successUrlPathnameQuery,
    failUrlPathnameQuery,
    activeStickerPackIdQuery,
    activeGiftIdQuery,
    activePresentIdQuery,
    activeTabQuery,
    minCreditsIncomeQuery,
    starterPopupQuery,
    packageIdQuery,
    changeCardQuery,
    supportWidgetQuery,
    chatRequestIdQuery,
    messageIdQuery,
    eventDataIdQuery,
    deliveryIdQuery,
    mediaSrcQuery,
    userNameQuery,
  };
};
