import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { UserProfile } from 'types/interfaces/user/UserProfile';

import { UserProfileApi } from 'api/UserProfileApi';

export const BASE_USER_PROFILE_QUERY_KEY = 'user-profile-query';

const TIME = 60 * 1000 * 5;

export const getUserProfileQueryKey = (userId: string) => {
  return `${BASE_USER_PROFILE_QUERY_KEY}-${userId}`;
};

export const useUserProfileQuery = (
  userId: string,
  queryOptions?: UseQueryOptions<UserProfile, any>
) => {
  const queryClient = useQueryClient();

  const cachedData = queryClient.getQueryData<UserProfile>(
    getUserProfileQueryKey(userId)
  );

  return useQuery<UserProfile>(
    getUserProfileQueryKey(userId),
    () => UserProfileApi.fetchPublicProfile({ userId }),
    {
      retry: false,
      staleTime: TIME,
      cacheTime: TIME,
      initialData: cachedData,
      ...queryOptions,
    }
  );
};

export const useUserProfileQueryCache = () => {
  const queryClient = useQueryClient();

  const updateProfileData = useCallback(
    ({
      payload,
      userId,
    }: {
      payload: Partial<UserProfile>;
      userId?: string;
    }) => {
      if (userId) {
        const cachedData = queryClient.getQueryData<UserProfile>(
          getUserProfileQueryKey(userId)
        );

        if (cachedData) {
          queryClient.setQueryData(getUserProfileQueryKey(userId), {
            ...cachedData,
            ...payload,
          });
        }
      }
    },
    [queryClient]
  );

  const updateProfileMediaSources = useCallback(
    ({
      payload,
      userId,
    }: {
      payload: Partial<UserProfile>;
      userId?: string;
    }) => {
      if (userId) {
        const cachedData = queryClient.getQueryData<UserProfile>(
          getUserProfileQueryKey(userId)
        );

        if (cachedData) {
          const privatePhotos = cachedData.private_photos.map((oldPhoto) => {
            const updatedPhoto = payload.private_photos?.find(
              (newPhoto) => newPhoto.id === oldPhoto.id
            );

            if (updatedPhoto) {
              return { ...oldPhoto, ...updatedPhoto };
            }

            return oldPhoto;
          });

          const newPayload = { ...payload, private_photos: privatePhotos };

          queryClient.setQueryData(getUserProfileQueryKey(userId), {
            ...cachedData,
            ...newPayload,
          });
        }
      }
    },
    [queryClient]
  );

  return { updateProfileData, updateProfileMediaSources };
};

export const useInvalidateUserProfileQuery = (userId?: string) => {
  const queryClient = useQueryClient();

  const makeCacheInvalid = useCallback(() => {
    if (userId) {
      queryClient.invalidateQueries(getUserProfileQueryKey(userId));
    }
  }, [queryClient, userId]);

  return { makeCacheInvalid };
};
