import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';

type PropTypes = {
  variant?: 'light' | 'dark' | 'black';
  withoutLink?: boolean;
  className?: string;
};

export const Logo: React.FC<PropTypes> = memo(
  ({ variant = 'light', withoutLink = false, className }) => {
    const height = '26px';

    if (withoutLink) {
      return (
        <img
          height={height}
          className={className}
          src={`/logos/${MirrorService.siteName}-${variant}.svg`}
        />
      );
    }

    return (
      <Link to="/">
        <img
          height={height}
          className={className}
          src={`/logos/${MirrorService.siteName}-${variant}.svg`}
        />
      </Link>
    );
  }
);
