import { AppDispatch, AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { ChatDialogTab } from 'types/enums/chat/ChatDialogTab';
import { ChatMessageFormat } from 'types/enums/chat/ChatMessageFormat';
import { ChatMessageStatus } from 'types/enums/chat/ChatMessageStatus';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatSticker } from 'types/interfaces/chat/ChatSticker';
import { Photo } from 'types/interfaces/Photo';

import { DialogsApi } from 'api/DialogsApi';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

import {
  addChatRequest,
  addChatRequests,
  addDialog,
  addDialogs,
  addMessage,
  addMessages,
  hideDialog,
  openGift,
  removeChatRequest,
  replaceDisappearingMessageWithGeneralMessage,
  setChatRequests,
  setChatRequestsFirstLoaded,
  setChatRequestsLoading,
  setDialogs,
  setDialogsFilters,
  setDialogsLoading,
  setGifts,
  setGiftsLoading,
  setMessages,
  setMessagesLoading,
  setStickerPacks,
  setStickerPacksLoading,
  unHideDialog,
  updateChatRequestReadStatus,
  updateChatRequestWithNewMessage,
  updateDialogStatus,
  updateDialogWithNewMessage,
} from './messengerSlice';

const RESTORE_REQUESTS_LOGIN_IDS = ['29', '36'];

export const fetchDialogsThunk =
  (updatedFilters: Partial<ChatDialogFilters>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    dispatch(setDialogsLoading(true));

    const finalFilters = {
      ...dialogsFilters,
      ...updatedFilters,
      tab: updatedFilters?.tab ? updatedFilters?.tab : ChatDialogTab.All,
    };

    const dialogsResponse =
      await DialogsApi.fetchDialogsWithFilters(finalFilters);

    dispatch(setDialogsFilters(finalFilters));
    dispatch(setDialogsLoading(false));
    dispatch(setDialogs(dialogsResponse));
  };

export const fetchOneDialogThunk =
  (contactId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const dialogsResponse =
      await DialogsApi.fetchOneDialogWithFilters(contactId);

    dispatch(addDialog(dialogsResponse.data));
  };

export const refetchDialogsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const dialogsResponse =
      await DialogsApi.fetchDialogsWithFilters(dialogsFilters);

    dispatch(setDialogs(dialogsResponse));
  };

export const fetchMoreDialogsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { dialogsLoading } = getState().messenger;
    const nextUrl = getState().messenger.dialogs.next;

    if (dialogsLoading || !nextUrl) return;

    const dialogsResponse = await DialogsApi.fetchDialogs(nextUrl);

    dispatch(addDialogs(dialogsResponse));
  };

export const fetchChatRequestsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { isChatRequestsFirstLoaded } = getState().messenger;

    if (isChatRequestsFirstLoaded) return;

    dispatch(setChatRequestsLoading(true));

    const chatRequestsResponse = await DialogsApi.fetchChatRequests();

    dispatch(setChatRequestsFirstLoaded());
    dispatch(setChatRequestsLoading(false));
    dispatch(
      setChatRequests({
        data: chatRequestsResponse.data,
        next: chatRequestsResponse.links?.next,
        total: chatRequestsResponse.total,
      })
    );
  };

export const fetchOneChatRequestThunk =
  (contactId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const chatRequestsResponse =
      await DialogsApi.fetchOneChatRequests(contactId);

    dispatch(addChatRequest(chatRequestsResponse.data));
  };

export const refetchChatRequestsThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const chatRequestsResponse = await DialogsApi.fetchChatRequests();

    dispatch(
      setChatRequests({
        data: chatRequestsResponse.data,
        next: chatRequestsResponse.links?.next,
        total: chatRequestsResponse.total,
      })
    );
  };

export const fetchMoreChatRequestsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { isChatRequestsLoading } = getState().messenger;
    const nextUrl = getState().messenger.chatRequests.next;

    if (isChatRequestsLoading || !nextUrl) return;

    const chatRequestsResponse = await DialogsApi.fetchChatRequests(nextUrl);

    dispatch(
      addChatRequests({
        data: chatRequestsResponse.data,
        next: chatRequestsResponse.links?.next,
        total: chatRequestsResponse.total,
      })
    );
  };

export const refetchDialogsWithFiltersThunk =
  (payload: Partial<ChatDialogFilters>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const updatedFilters = { ...dialogsFilters, ...payload };

    if (dialogsFilters.search === payload.search) return;

    dispatch(setDialogsLoading(true));
    dispatch(setDialogsFilters(updatedFilters));

    const dialogsResponse =
      await DialogsApi.fetchDialogsWithFilters(updatedFilters);

    dispatch(setDialogsLoading(false));
    dispatch(setDialogs(dialogsResponse));
  };

export const updateDialogHideStatusThunk =
  (payload: {
    contactId: string;
    shouldHide: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const data = await DialogsApi.updateDialogHideStatus(
      payload.contactId,
      payload.shouldHide
    );

    if (data.status && payload.shouldHide) {
      dispatch(
        hideDialog({
          contactId: payload.contactId,
        })
      );

      if (dialogsFilters.tab !== ChatDialogTab.Hidden) {
        payload.cb && payload.cb();
      }
    }

    if (data.status && !payload.shouldHide)
      dispatch(
        unHideDialog({
          contactId: payload.contactId,
        })
      );
  };

export const hideChatRequestThunk =
  (payload: { contactId: string; cb?: () => void }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const data = await DialogsApi.hideChatRequest(payload.contactId);

    if (data.status) {
      dispatch(
        removeChatRequest({
          contactId: payload.contactId,
        })
      );

      if (dialogsFilters.tab !== ChatDialogTab.Hidden)
        payload.cb && payload.cb();
    }
  };

export const fetchMessagesThunk =
  (payload: {
    contactId: string;
    partnerLoginId: string | null;
    messageId: string | null;
    requestId: string | null;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { prevPageUrl } = getState().common;

    const isRestoreRequest = RESTORE_REQUESTS_LOGIN_IDS.includes(
      payload.partnerLoginId ||
        new URLSearchParams(prevPageUrl).get('partner_login_id') ||
        ''
    );

    dispatch(setMessagesLoading(true));

    if (isRestoreRequest) {
      try {
        await DialogsApi.restoreRequestMessages({
          contactId: payload.contactId,
          messageId: payload.requestId,
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (isRestoreRequest && !payload.requestId) {
      try {
        await DialogsApi.restoreDialogMessages({
          contactId: payload.contactId,
          messageId: payload.messageId,
        });
      } catch (error) {
        console.error(error);
      }
    }

    const messagesResponse = await DialogsApi.fetchMessages(payload.contactId);

    if (isRestoreRequest && !messagesResponse.messages.length) {
      dispatch(
        setActiveSystemPopup({ type: SystemPopupTypes.DisappearingMessage })
      );
    }

    dispatch(
      setMessages({
        next: messagesResponse.next,
        contact: messagesResponse.contact,
        contactId: messagesResponse.contact.ulid_id,
        isLiked: messagesResponse.is_liked,
        isEnabledPresents: messagesResponse.is_real_gift_available,
        dialog: messagesResponse.dialog,
        messages: messagesResponse.messages,
      })
    );
    dispatch(setMessagesLoading(false));
  };

export const restoreDialogThunk =
  (payload: {
    contactId: string;
    partnerLoginId: string | null;
    messageId: string | null;
    requestId: string | null;
  }): AppThunk =>
  async () => {
    const isRestoreRequest = RESTORE_REQUESTS_LOGIN_IDS.includes(
      payload.partnerLoginId || ''
    );

    if (isRestoreRequest) {
      try {
        await DialogsApi.restoreRequestMessages({
          contactId: payload.contactId,
          messageId: payload.requestId,
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (isRestoreRequest && !payload.requestId) {
      try {
        await DialogsApi.restoreDialogMessages({
          contactId: payload.contactId,
          messageId: payload.messageId,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

export const fetchMoreMessagesThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { contactId } = payload;
    const { messenger } = getState();

    const nextUrl = messenger.messages[contactId]?.next;
    const isMessagesLoading = messenger.messagesLoading;

    if (!nextUrl || isMessagesLoading) return;

    dispatch(setMessagesLoading(true));

    const messagesResponse = await DialogsApi.fetchMoreMessages(nextUrl);

    dispatch(
      addMessages({
        messages: messagesResponse.messages,
        contactId,
        next: messagesResponse.next,
      })
    );
    dispatch(setMessagesLoading(false));
  };

export const readIncomingMessagesThunk =
  (payload: {
    contactId: string;
    messageId?: string | null;
    chatRequestId?: number | null;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!payload.messageId && !payload.chatRequestId) return;

    if (payload.chatRequestId) {
      await DialogsApi.readChatRequest(payload.chatRequestId);

      dispatch(
        updateChatRequestReadStatus({
          contactId: payload.contactId,
          isIncoming: true,
        })
      );

      return;
    }

    if (payload.messageId) {
      await DialogsApi.readIncomingMessages(payload.messageId);

      dispatch(
        updateDialogStatus({ contactId: payload.contactId, isIncoming: true })
      );
    }
  };

export const sendTextMessageThunk =
  (payload: { contactId: string; body: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: payload.body,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Text,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
          request_id: null,
        },
        contactId: payload.contactId,
      })
    );

    DialogsApi.sendTextMessage({
      contactId: payload.contactId,
      body: payload.body,
      frontMessageId,
    });
  };

export const sendGiftMessageThunk =
  (payload: {
    contactId: string;
    gift: ChatGift;
    message: string;
    packageId: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: payload.message || null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Gift,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
          gift: payload.gift,
          request_id: null,
          is_gift_opened: false,
        },
        contactId: payload.contactId,
      })
    );

    await DialogsApi.sendGiftMessage({
      contactId: payload.contactId,
      giftId: payload.gift.id,
      body: payload.message,
      packageId: payload.packageId,
      frontMessageId,
    });
  };

export const sendStickerMessageThunk =
  (payload: { contactId: string; sticker: ChatSticker }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          id: '',
          body: null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Sticker,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
          sticker: payload.sticker,
          request_id: null,
        },
        contactId: payload.contactId,
      })
    );

    await DialogsApi.sendStickerMessage({
      contactId: payload.contactId,
      stickerId: payload.sticker.id,
      frontMessageId,
    });
  };

export const sendPhotoMessageThunk =
  (payload: { contactId: string; photo: Photo }): AppThunk =>
  async () => {
    await DialogsApi.sendPhotoMessage({
      contactId: payload.contactId,
      photoId: payload.photo.id,
      format: ChatMessageFormat.Photo,
    });
  };

export const addNewMessageFromSocketThunk =
  (payload: { message: ChatMessage; contactId: string }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { dialogs, chatRequests } = getState().messenger;

    dispatch(addMessage(payload));

    if (!payload.message.is_incoming) {
      dispatch(
        replaceDisappearingMessageWithGeneralMessage({
          contactId: payload.contactId,
        })
      );
    }

    const hasDialogWith = (contactId: string) => (dialog: ChatDialog) =>
      dialog.contact?.ulid_id === contactId;

    if (payload.message.request_id) {
      if (chatRequests.data.some(hasDialogWith(payload.contactId))) {
        dispatch(updateChatRequestWithNewMessage(payload));
      } else {
        dispatch(fetchOneChatRequestThunk(payload.contactId));
      }

      return;
    }

    if (dialogs.data.some(hasDialogWith(payload.contactId))) {
      dispatch(updateDialogWithNewMessage(payload));
    } else {
      dispatch(fetchOneDialogThunk(payload.contactId));
    }
  };

export const fetchGiftsThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setGiftsLoading(true));

    const { data: giftsResponse } = await DialogsApi.fetchGifts();

    dispatch(setGifts(giftsResponse));
    dispatch(setGiftsLoading(false));
  };

export const fetchStickerPacksThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setStickerPacksLoading(true));

    const { data: stickerPacksResponse } = await DialogsApi.fetchStickerPacks();

    dispatch(setStickerPacks(stickerPacksResponse));
    dispatch(setStickerPacksLoading(false));
  };

export const openGiftThunk =
  (payload: { contactId: string; messageId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    DialogsApi.openGift({ messageId: payload.messageId });

    dispatch(openGift(payload));
  };
