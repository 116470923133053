export enum PopupOperation {
  Click = 'click',
  Show = 'show',
  Close = 'close',
  ClickClose = 'click_close',
  ClickChange = 'click_change',
  ClickAccept = 'click_accept',
  ClickOpen = 'click_open',
}

export enum PopupName {
  ChatRequestsNavigationBar = 'chat_requests_navigation_bar',
  CreditBalanceIsLowChat = 'credit_balance_is_low_chat',

  ConfirmationProfilePopup = 'confirmation_profile_popup',
  ConfirmationNotification = 'confirmation_notification',
  ConfirmationBanner = 'confirmation_banner',
  ConfirmationAfterRegPopup = 'confirmation_after_reg',
  ConfirmationSessionResendPopup = 'confirmation_session_resend',
  ConfirmationSessionInboxPopup = 'confirmation_session_inbox',
  ConfirmationSessionRemindLaterPopup = 'confirmation_session_remind_later',

  FinderFilter = 'finder_filter',
  InitFreeCredits = '20_free_credits',
  SettingsLogout = 'settings_logout',
  CreditBalanceIsLow = 'credit_balance_is_low',
  PurchaseV1 = 'purchase_v1',
  PurchaseV2 = 'purchase_v2',
  PurchaseV3 = 'purchase_v3',

  OnlineReactivation = 'online_reactivation',
  EmailUnsubscribe = 'email_unsubscribed',

  PWAAfterRemainder = 'after_reminder_pop_up',
  PWAAfterProfile = 'after_profile_pop_up',
  PWAAfterWizard = 'after_wizard',
  PWAAfterPurchase = 'pwa_after_purchase',
  PWASystem = 'system_pwa',
  PWAPromote = 'pwa_promote',

  DisappearingMessage = 'disappearing_message',
}
