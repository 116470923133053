import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LAST_ONLINE_TS_STORAGE_KEY } from 'config/storageConstants';

import { getSessionStorageItem } from 'helpers/sessionStorage';
import { getIsAuthenticated } from 'store/auth/selectors';
import { getActiveSystemPopupTypeSelector } from 'store/systemPopup/selectors';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

const OFFLINE_TIMER = 10 * 60 * 1000;
const CHECK_OFFLINE_INTERVAL = 30 * 1000;

export const useOnlineOnClickPopup = () => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const isAuthenticated = useSelector(getIsAuthenticated);
  const activeSystemPopupType = useSelector(getActiveSystemPopupTypeSelector);

  const dispatch = useDispatch();

  const checkIsOfflinePopupMustBeShown = useCallback(() => {
    const lastOnlineTimeStamp = getSessionStorageItem(
      LAST_ONLINE_TS_STORAGE_KEY
    );
    const timeDiff = Date.now() - Number(lastOnlineTimeStamp);

    if (
      isAuthenticated &&
      lastOnlineTimeStamp &&
      timeDiff > OFFLINE_TIMER &&
      activeSystemPopupType !== SystemPopupTypes.OnlineOnClick
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.OnlineOnClick,
        })
      );
    }

    timeoutId.current = setTimeout(
      checkIsOfflinePopupMustBeShown,
      CHECK_OFFLINE_INTERVAL
    );
  }, [activeSystemPopupType, dispatch, isAuthenticated]);

  useEffect(() => {
    checkIsOfflinePopupMustBeShown();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [checkIsOfflinePopupMustBeShown]);
};
