import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LAST_ONLINE_TS_STORAGE_KEY } from 'config/storageConstants';
import { MirrorService } from 'services/MirrorService';
import { PopupName, PopupOperation } from 'types/enums/Popup';

import { setSessionStorageItem } from 'helpers/sessionStorage';
import { usePopupTracking } from 'hooks/tracking/usePopupTracking';
import { getUser } from 'store/auth/selectors';

import { BaseButton } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './restartSessionPopup.module.sass';

interface Props {
  onClose: () => void;
}

export const RestartSessionPopup: FC<Props> = ({ onClose }) => {
  const user = useSelector(getUser);

  const { trackPopup } = usePopupTracking();

  const handleClose = () => {
    setSessionStorageItem(LAST_ONLINE_TS_STORAGE_KEY, Date.now());

    onClose();

    trackPopup({
      popupName: PopupName.OnlineReactivation,
      operation: PopupOperation.Click,
    });
  };

  useEffect(() => {
    trackPopup({
      popupName: PopupName.OnlineReactivation,
      operation: PopupOperation.Show,
    });
  }, [trackPopup]);

  return (
    <Popup>
      <div className={css.popupContent}>
        <UserAvatar
          url={MirrorService.resolveImagePath(user?.photo_url)}
          size="125px"
        />

        <div className={css.textWrapper}>
          <p className={css.inactiveTimeInfo}>You’re inactive for 10 minutes</p>
          <p>To continue, please press the button below.</p>
        </div>

        <div className={css.buttonWrapper}>
          <BaseButton className={css.button} onClick={handleClose}>
            CONTINUE USING
          </BaseButton>
        </div>
      </div>
    </Popup>
  );
};
