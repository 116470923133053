export enum ConversionEventName {
  AddToCart = 'AddToCart',
  AddToWishlist = 'AddToWishlist',
  CompleteRegistration = 'CompleteRegistration',
  Purchase = 'Purchase',
  PurchaseTotal = 'PurchaseTotal',
  Subscribe = 'Subscribe',
  UserSpent1cr = 'UserSpent1cr',
  UserSpent5cr = 'UserSpent5cr',
  UserSpent10cr = 'UserSpent10cr',
  UserSpent15cr = 'UserSpent15cr',
  UserSpent20cr = 'UserSpent20cr',
  FindLocation = 'FindLocation',
  EngagedUserV1 = 'EU1',
  EUSubmit = 'EU_submit',
  EURetention = 'EU_retention',
  EU30min = 'EU_30min',
}

export enum ConversionEventType {
  Registration = 'registration',
  FirstPurchase = 'first_purchase',
  PurchaseTotal = 'purchase_total',
  MailConfirm = 'mail_confirm',
  PhotoUpload = 'photo_upload',
  UserSpent1cr = 'user_spent_1cr',
  UserSpent5cr = 'user_spent_5cr',
  UserSpent10cr = 'user_spent_10cr',
  UserSpent15cr = 'user_spent_15cr',
  UserSpent20cr = 'user_spent_20cr',
  FindLocation = 'find_location',
  EngagedUserV1 = 'EU1',
  EUSubmit = 'EU_submit',
  EURetention = 'EU_retention',
  EU30min = 'EU_30min',
}

export enum GtmConversionEventName {
  Registration = 'registration',
  MailConfirm = 'mail-confirm',
  Sale = 'sale',
  Resale = 'rsale',
  CardSubmit = 'submit',
  SpendFirstCredit = 'spend',
  Spend15Credits = 'spend15',
  EngagedUserV1 = 'eu_1',
  EURetention = 'EU_retention',
  EU30min = 'EU_30min',
  EUSubmit = 'EU_submit',
}
